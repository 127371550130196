
.video-wrap {
    // @apply absolute w-full h-full top-0 left-0;
    position: relative;
    flex-grow: 1;

        
    .player-wrap {
        @apply absolute w-full h-full top-0 left-0;
        /* transition: all .5s ease; */

        &.g-true video, .player-wrap.g-true .effect {
            mask-image: url('../assets/animated-pattern.gif');
            mask-size: 7.5px 405px;
        }
        &.g-true video {
            mask-position: 0px 0px;
            z-index: 1;
            position: relative;
        }
        &.g-true .effect {
            background: $pink;
            mask-position: 0px 2px;
            animation: flip-colors-3 1s infinite;
        }
        .video-controls {
            /* display: none; */
        }

        canvas, .effect {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            pointer-events: none;
        }
    }
}


.video-player {
    @apply object-cover h-full mx-auto w-full;
    transition: opacity .5s ease;
}
.glitch-player {
    @apply cursor-none aspect-4/3 object-cover max-h-screen mx-auto w-[1920px] absolute;
}

.video-controls {
    @apply flex z-[1] text-light absolute bottom-0 items-baseline w-full justify-start;
    transition: opacity 1s;
}

.video-overlay {
    @apply absolute h-screen w-screen bg-[rgba(0,0,0,0.8)] z-50 p-4;
}

.video-overlay dialog { @apply w-full mx-auto bg-[transparent] block; }

@keyframes flip-colors-3 {
    0% {
        background: linear-gradient(0deg, $pink 0%, $cyan 100%);
    }
    25% {
        background: linear-gradient(0deg, $cyan 0%, #ffff00 100%);
    }
    50% {
        background: linear-gradient(0deg, #ffff00 0%, #cccccc 100%);
    }
    100% {
        background: linear-gradient(0deg, #cccccc 0%, $pink 100%);
    }
}



input[type="range"] {
    @apply pointer-events-none;
}

.player-wrap.debug input[type="range"] {
    @apply pointer-events-none;
}

input[type="range"]::-moz-range-progress,
input[type="range"]::-ms-fill-lower {
    @apply bg-[transparent]; 
    foo: bar;
}
input[type="range"]::-moz-range-track,
input[type="range"]::-ms-fill-upper  {  
    background-color: transparent;
}
 
@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
        overflow: hidden;
        width: 100%;
        -webkit-appearance: none;
        background-color: transparent;
        height: 2px;
    }
    input[type='range']::-webkit-slider-runnable-track {
        height: .15em;
        -webkit-appearance: none;
        color: #ffffff88;
        margin-top: -1px;
    }
    input[type='range']::-webkit-slider-thumb {
        width: 0px;
        -webkit-appearance: none;
        height: 2px;
        cursor: ew-resize;
        background: transparent;
        // position: relative;
        box-shadow: -100vw 0 0 100vw #ffffff88;
    }
} /* end media */ 

.bufferBar {
    @apply absolute top-0 left-0 h-full bg-light;
    animation: stretchin 60s linear infinite;
}
@keyframes stretchin {
    from {
        width: 0%;
        left: 0%
    }
    50% {
        width: 100%;
        left: 0%
    }
    to {
        width: 0%;
        left: 100%;
    }
}

#loading { height: 10%; }
#loading path {
    stroke-dasharray: 300;
    stroke-dashoffset: 0;
    animation: cycle 3s linear infinite;
}
@keyframes cycle {
    0% {
        stroke-dashoffset: 600;
    }
    50% {
        stroke-dashoffset: 300;
    }
    100% {
      stroke-dashoffset: 0;
    }
}
#loading.hide {
    transition: opacity 1s;
    opacity: 0;
}


.inset-video-player {
    --scale: 0.25;
    @media screen and (min-width: 1024px) and (min-height:600px) {
        --scale: 0.35;
    }
    @media screen and (min-width: 1280px) {
        --scale: .5;
    }
    z-index: 9;
    width: calc(512px * var(--scale)); // base 512;
    height: calc(512px * var(--scale));

    position: absolute;
    top: calc(256px * var(--scale));
    right: calc(64px * var(--scale));
    filter: drop-shadow(0px 4px 2px transparentize($pink,.5));
    video {
        mask-image: url('../assets/Overlay-mask.png');
        mask-size: contain;
        width: 100%;
        height: 100%;
        background-color: #000;
    }
    // &:after {
        // content: '';
        // position: absolute;
        
        background-image: url('../assets/Overlay-outline.png');
        background-size: contain;
        // left: 0; top: 0; right: 0; bottom: 0;
    // }
}