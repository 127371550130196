
.videonav {
    flex-grow: 0;
    background-color: #000;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;

    --scale: 0.25;
    @media screen and (min-width: 1024px) and (min-height:600px) {
        --scale: 0.35;
    }
    @media screen and (min-width: 1280px) {
        --scale: .5;
    }
    .App:has(.flip-msg) & {
        display: none;
    }
}
.videonav li {
    cursor: default;
    pointer-events: none;
    position: relative;
    opacity: 0;
    width: calc(256px * var(--scale)); // base dimension is 256px
    height: calc(256px * var(--scale));
    flex-shrink: 0;
    flex-grow: 0;
    background-size: cover;
    background-position: top;
    overflow: hidden;
    line-height: 512px;
    user-select: none;
    
    &.concert {
        background-image: url('../assets/concert.png');
    }
    &.cat {
        background-image: url('../assets/cat.png');
    }
    &.singer {
        background-image: url('../assets/singer.png');
    }
    &.rave {
        background-image: url('../assets/rave.png');
    }
    &.tapper {
        background-image: url('../assets/tapper.png');
    }
    &.cake {
        background-image: url('../assets/cake.gif');
    }
    &.pov {
        background-image: url('../assets/pov.png');
    }
    &.bat {
        background-image: url('../assets/bat.png');
    }

        


    &.active {
        opacity: 1;
        cursor: pointer;
        pointer-events: all;
        background-position: center;
        filter: drop-shadow(0px 0px 4px transparentize($cyan,.3));
    
        animation: flicker-in-icon .3s;

        &.takeover {
            background-position-y: calc(100% * 2/3);
            animation: flicker-in-takeover-icon .3s;

            &.count-0, &.count-1, &.count-2, &.count-3, &.count-4 {
                background-position-y: calc(100% / 3);
                animation: flicker-in-counting-takeover-icon .3s;
            }
        }
    }

    &.current {
        opacity: 1;
        pointer-events: none;
        cursor: default;
        color: $pink;
        background-position: bottom;
        filter: drop-shadow(0px 0px 4px transparentize($pink,.3));
        
        animation: flicker-active-icon .3s;
        
        &.toggle {
            pointer-events: all;
            cursor: pointer;
        }
        
        &.takeover {
            background-position-y: bottom;
            animation: flicker-active-takeover-icon .3s;
        }
    }
    &.clicked {
        &:not(.toggle):before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url('../assets/selected.png');
            background-size: contain;
        }
    }
  
    &.disabled {
        opacity: 1;
        background-position: top;
    }

    // account for 1024-1088 screens
    @media screen and (min-width: 1024px) and (max-width:1088px) {
        &:not(:first-child) {
            margin-left: -8px;
        }
        &:not(:last-child) {
            margin-right: -8px;
        }
    }

}
.videonav .divider {
    width: calc(4px * var(--scale));
    height: calc(192px * var(--scale));
    background-color: $cyan;
    margin-left: calc(62px * var(--scale));
    margin-right: calc(62px * var(--scale));
    opacity: 0;

    &:has( + .active) {
        opacity: 1;
        animation: flicker-in-line .3s;
    }
}
.videonav.d-false {
    
    li:not(.current), .divider {
        opacity: 0;
    }
    
    li.current {
        pointer-events: all;
        cursor: pointer;
        background-position: center;
        filter: drop-shadow(0px 0px 4px transparentize($cyan,.3));

        &:before {
            display: none;
        }
    }
    &:before {
        content: 'Select the icons to hack junctQín’s performance...';
        color: $pink;
        font-family: 'Retro-Gaming';
        font-size: calc(56px * var(--scale));
        line-height: calc(256px * var(--scale));
        text-align: left;
        left: calc( ( (100vw - ((256px * var(--scale)) * 8.5)) / 10 ) + (256px * var(--scale))); 
        padding-left: calc(120px * var(--scale));
        right: 0; top: 0; bottom: 0;
        position: absolute;
        background-image: url('../assets/arrow.gif'); //, url('../assets/Intro.png');
        background-position: left center; // calc(4em + (256px * var(--scale))) center; //, calc(4em + (376px * var(--scale))) center; // 4em padding + 128px icon + 40px arrow + 20px spacing
        background-repeat: no-repeat;
        
        background-size: calc(80px * var(--scale)); //, calc(1774px * var(--scale)); // 887px; // base 80px, 1774px
        filter: drop-shadow(0px 0px 4px transparentize($pink,.3));
    }
    &.m-true:before {
        left: calc( ( (100vw - ((256px * var(--scale)) * 6)) / 7 ) + (256px * var(--scale))); 
    }
}
.videonav:has(.cat.hilite:not(.current)) {
    &:before {
        content: '';
        left: calc( ( 2 * ( (100vw - ((256px * var(--scale)) * 8.5)) / 10 ) ) + ( 2 * (256px * var(--scale)) )); 
        right: 0; top: 0; bottom: 0;
        position: absolute;
        background-image: url('../assets/arrow.gif'); //, url('../assets/Intro.png');
        background-position: left center; // calc(4em + (256px * var(--scale))) center; //, calc(4em + (376px * var(--scale))) center; // 4em padding + 128px icon + 40px arrow + 20px spacing
        background-repeat: no-repeat;
        
        background-size: calc(80px * var(--scale)); //, calc(1774px * var(--scale)); // 887px; // base 80px, 1774px
        filter: drop-shadow(0px 0px 4px transparentize($pink,.3));
    }
    &.m-true:before {
        left: calc( ( ( 2 * (100vw - ((256px * var(--scale)) * 6)) / 7 ) ) + ( 2 * (256px * var(--scale)))); 
    }
}

@keyframes blinkOut {
    0% { display: block; }
    80% { display: none; }
    90% { display: block; }
    100% { display: none; }    
}

@keyframes flicker-in-icon {
    0% { background-position: top; }
    19% { background-position: top; }
    20% { background-position: center; }
    39% { background-position: center; }
    40% { background-position: top; }
    59% { background-position: top; }
    60% { background-position: center; }
    79% { background-position: center; }
    80% { background-position: top; }
    99% { background-position: top; }
    100% { background-position: center; }
}
@keyframes flicker-in-takeover-icon {
    0% { background-position-y: top; }
    19% { background-position-y: top; }
    20% { background-position-y: calc(100% * 2/3); }
    39% { background-position-y: calc(100% * 2/3); }
    40% { background-position-y: top; }
    59% { background-position-y: top; }
    60% { background-position-y: calc(100% * 2/3); }
    79% { background-position-y: calc(100% * 2/3); }
    80% { background-position-y: top; }
    99% { background-position-y: top; }
    100% { background-position-y: calc(100% * 2/3); }
}
@keyframes flicker-in-counting-takeover-icon {
    0% { background-position-y: top; }
    19% { background-position-y: top; }
    20% { background-position-y: calc(100% / 3); }
    39% { background-position-y: calc(100% / 3); }
    40% { background-position-y: top; }
    59% { background-position-y: top; }
    60% { background-position-y: calc(100% / 3); }
    79% { background-position-y: calc(100% / 3); }
    80% { background-position-y: top; }
    99% { background-position-y: top; }
    100% { background-position-y: calc(100% / 3); }
}
@keyframes flicker-active-icon {
    0% { background-position: center; }
    19% { background-position: center; }
    20% { background-position: bottom; }
    39% { background-position: bottom; }
    40% { background-position: center; }
    59% { background-position: center; }
    60% { background-position: bottom; }
    79% { background-position: bottom; }
    80% { background-position: center; }
    99% { background-position: center; }
    100% { background-position: bottom; }
}
@keyframes flicker-active-takeover-icon {
    0% { background-position-y: calc(100% * 2/3); }
    19% { background-position-y: calc(100% * 2/3); }
    20% { background-position: bottom; }
    39% { background-position: bottom; }
    40% { background-position-y: calc(100% * 2/3); }
    59% { background-position-y: calc(100% * 2/3); }
    60% { background-position: bottom; }
    79% { background-position: bottom; }
    80% { background-position-y: calc(100% * 2/3); }
    99% { background-position-y: calc(100% * 2/3); }
    100% { background-position: bottom; }
}


@keyframes flicker-in-line {
    0% { background-color: #666; }
    19% { background-color: #666; }
    20% { background-color: $cyan; }
    39% { background-color: $cyan; }
    40% { background-color: #666; }
    59% { background-color: #666; }
    60% { background-color: $cyan; }
    79% { background-color: $cyan; }
    80% { background-color: #666; }
    99% { background-color: #666; }
    100% { background-color: $cyan; }
}


// special handling for cake icon.
.videonav li.takeover {
    &.count-0, &.count-1, &.count-2, &.count-3, &.count-4 {
        &:after {
            content: '';
            width: 100%;
            height: calc(52% / 2.56); // sprite-frame is 52px high. space is 256px high
            position: absolute;
            bottom: 2px;
            left: 0;
            background-image: url(../assets/cake-countdown.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top;
        }
    }
    &.count-1:after { background-position-y: calc(100% * 1/4); }
    &.count-2:after { background-position-y: calc(100% * 2/4); }
    &.count-3:after { background-position-y: calc(100% * 3/4); }
    &.count-4:after { background-position-y: bottom; }

    &.loading {
        opacity: 1;
        background-position-y: calc(100% * 2/3);
        filter: drop-shadow(0px 0px 4px transparentize($cyan,.3));

        &:after {
            content: '';
            width: 100%;
            height: calc(44% / 2.56); // sprite-frame is 44px high. space is 256px high
            position: absolute;
            bottom: 2px;
            left: 0;
            background-image: url(../assets/cake-loading-txt.gif), url(../assets/cake-loading-bar.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top;
        }
        &.load-1:after { background-position-y: calc(100% * 1/11); }
        &.load-2:after { background-position-y: calc(100% * 2/11); }
        &.load-3:after { background-position-y: calc(100% * 3/11); }
        &.load-4:after { background-position-y: calc(100% * 4/11); }
        &.load-5:after { background-position-y: calc(100% * 5/11); }
        &.load-6:after { background-position-y: calc(100% * 6/11); }
        &.load-7:after { background-position-y: calc(100% * 7/11); }
        &.load-8:after { background-position-y: calc(100% * 8/11); }
        &.load-9:after { background-position-y: calc(100% * 9/11); }
        &.load-10:after { background-position-y: calc(100% * 10/11); }
        &.load-11:after { background-position-y: bottom; }
    }

}