@tailwind base;
@tailwind components;
@tailwind utilities;

$pink: #f351f4;
$cyan: #0be4f7;

@import './style/player';
@import './style/videonav';
@import './style/endframe.css';
@import './style/credits.css';
@import './style/share.css';


@font-face {
  font-family: 'Retro-Gaming';
  src:  url('assets/fonts/Retro-Gaming.ttf.woff') format('woff'),
  url('assets/fonts/Retro-Gaming.ttf.svg#Retro-Gaming') format('svg'),
  url('assets/fonts/Retro-Gaming.ttf.eot'),
  url('assets/fonts/Retro-Gaming.ttf.eot?#iefix') format('embedded-opentype'); 
  font-weight: normal;
  font-style: normal;
}

.App {
  @apply text-light text-center bg-dark h-[100dvh] w-screen flex items-stretch justify-center flex-col;

}
nav {
  background-image: url('assets/top-fill.png');
  background-color: #000;
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 8.5px 78px; // 15px 156px;
  --scale: 0.25;
  @media screen and (min-width: 1024px) and (min-height:600px) {
    --scale: 0.35;
  }
  @media screen and (min-width: 1280px) {
      --scale: .5;
  }
  @apply hidden;
}
nav, .videonav, .flip-msg {
  &:after {
    content: '';
    background-image: url('assets/overlay-pattern.png');
    // background-size: 
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: .25;
    mix-blend-mode: hard-light;
    z-index: 99;
  }
}
nav svg { @apply text-lg; }
nav img { filter: drop-shadow(0px 0px 4px transparentize($cyan,.3));  }
nav img.close-icon { width: calc(56px * var(--scale)); }
nav img.share-icon { width: calc(72px * var(--scale)); }
nav img.fullscreen-icon { width: calc(48px * var(--scale)); }
nav img.fb-icon { width: calc(54px * var(--scale)); margin-left: calc(21px * var(--scale)); }
nav img.tw-icon { width: calc(96px * var(--scale)); }
nav > button { 
  user-select: none;
  width: calc(72px * var(--scale));
  img { margin-left: auto; margin-right: auto; user-select: none; -webkit-user-drag: none; }
}
nav:has(.default-share.active) img.share-icon {
  filter: hue-rotate(118deg) saturate(1.43) drop-shadow(0px 0px 4px transparentize($pink,.3));
}

.end-frame,
.collective {
  @apply absolute h-full w-full z-[100] text-light;
}

.flip-msg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  background-image: url('assets/flip_animation.gif');
  background-repeat: no-repeat;
  background-position: center;
  // filter: drop-shadow(0px 0px 4px transparentize($cyan,.3));
  z-index: 99999;

  span {
    
    color: $pink;
    font-family: 'Retro-Gaming';
    font-size: calc(56px * var(--scale));
    line-height: 1.2em;

    position: absolute;
    bottom: 2em;
    right: 2em;
    // top: 2em;
    left: 2em;
    // background-image: url('assets/flip_txt.png');
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position: bottom;
    filter: drop-shadow(0px 0px 4px transparentize($pink,.3));

    em {
      font-style: normal;
      color: $cyan;
    }
  }
}